var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: [_vm.namespace(), _vm.skinStyle] }, [
    _c("div", { class: [_vm.namespace("layout-grid")] }, [
      _c(
        "div",
        { class: _vm.namespace("data-table") },
        [
          _c("markets-ui-data-table", {
            attrs: {
              skin: _vm.watchedSettings.skin,
              size: "medium",
              "header-configs": _vm.headerConfigs,
              "row-data": _vm.highlightRowData
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.namespace("content-wrapper") },
        [
          _c("markets-ui-menus", {
            attrs: { skin: _vm.skin, "data-model": _vm.groupMenuLabels },
            on: { change: _vm._changeTabType }
          }),
          _vm._v(" "),
          _c("div", [
            _c("article", { class: _vm.namespace("tab-article") }, [
              _c("div", { class: _vm.namespace("tab-article__content") }, [
                _c("p", [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.tabContent) +
                      "\n                        "
                  )
                ])
              ])
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }