<template>
    <section :class="[namespace('section'), skinStyle]">
        <div :class="[namespace('layout')]">
            <markets-ui-grid
                :skin="skin"
                :selectRowIds="selectRowIds"
                :option="gridOption"
                :columns="gridColumns"
                :rows="dataModel"
                :labels="gridLabels"
                @render-complete="renderComplete"
            >
            </markets-ui-grid>
        </div>
    </section>
</template>

<script>
import mwcMarketsCore from 'mwc-markets-core';
import { getCompetitorsBasicInfoDataPoints } from '../metadata/data-point';

const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'research-competitors',
    mixins: [mixins.componentUI],
    props: {
        skin: {
            type: String,
            default: ''
        },
        dataModel: {
            type: Array,
            default: function() {
                return [];
            }
        },
        /**
         * The labels, come from research ui component.
         */
        initedLabels: {
            type: Object
        },
        settings: {
            type: Object
        }
    },
    data() {
        return {
            selectRowIds: this.dataModel.length ? [this.dataModel[0].id] : null
        };
    },
    created() {
        this.namespace = utils.namespace('research-competitors');
    },
    watch: {
        dataModel: {
            handler() {
                if (this.dataModel.length) {
                    this.selectRowIds = [this.dataModel[0].id];
                }
            },
            deep: true
        }
    },
    computed: {
        skinStyle() {
            const skinSuffix = utils.getSkinSuffix(this.skin);
            return `${this.namespace()}--${skinSuffix}`;
        },
        gridLabels() {
            const labels = {};
            [
                'previousPage',
                'previousColumn',
                'nextPage',
                'nextColumn'
            ].forEach(key => {
                labels[key] = this.initedLabels[key];
            });
            return labels;
        },
        gridOption() {
            return {
                autoHeight: this.settings.autoHeight,
                frozenColumn: 0,
                layout: this.settings.layout,
                stickyLeadBreakPoints: this.settings.stickyLeadBreakPoints,
                selectable: true
            };
        },
        gridColumns() {
            const columns = getCompetitorsBasicInfoDataPoints();
            const nameProperty = 'name';
            columns.forEach(column => {
                column[nameProperty] = this.initedLabels[column.id];
                // moat value have different label in competitors component.
                if (column.id === 'moat') {
                    column[nameProperty] = this.initedLabels['economicMoat'];
                }
                column['sortable'] = false;
            });
            return columns;
        }
    },
    methods: {
        /**
         * When grid render complete set the first row focus.
         */
        renderComplete() {
            // special case just when layout is mbg
            if (this.settings.layout === 'mbg' && this.dataModel.length > 0) {
                this.selectRowIds = [this.dataModel[0].id];
            }
        }
    }
};
</script>
<style lang="scss">
$namespace: 'mwc-markets-research-competitors';
.mwc-markets-research.markets-ui-container__fixed-height {
    .#{$namespace} {
        &-section {
            height: 100%;
        }
        &-layout {
            height: 100%;
        }
    }
}
</style>
