var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.namespace(),
      attrs: {
        autoHeight: _vm.watchedSettings.autoHeight,
        skin: _vm.watchedSettings.skin,
        errorMessage: _vm.errorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: _vm.watchedSettings.showSetting,
        showLoading: _vm.showLoading
      },
      on: { "setting-click": _vm.toggleSettings }
    },
    [
      _c(
        "span",
        {
          class: _vm.namespace("header__bold"),
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _vm._v("\n        " + _vm._s(_vm.title.main) + ": "),
          _c("span", [_vm._v(_vm._s(_vm.title.sub))])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _vm.showWebLayout && !_vm.errorMessage
            ? _c("markets-ui-menus", {
                class: _vm.namespace("view-menus"),
                attrs: {
                  dataModel: _vm.settingsPopoverNavSelectedList,
                  skin: _vm.watchedSettings.skin
                },
                on: { change: _vm.changeViewType }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.classes, attrs: { slot: "content" }, slot: "content" },
        [
          !_vm.showLoading && !_vm.errorMessage
            ? _c(_vm.currentSubComponent, {
                tag: "component",
                attrs: {
                  initedLabels: _vm.initedLabels,
                  dataModel: _vm.subComponentData,
                  settings: _vm.settings,
                  skin: _vm.watchedSettings.skin
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "markets-ui-settings",
        {
          ref: "researchUiSettings",
          attrs: {
            slot: "settings",
            skin: _vm.watchedSettings.skin,
            showBack: !!_vm.settingsPopoverNavSelected,
            "triggered-by": _vm.settingsPopoverTriggeredId,
            visible: _vm.settingsPopoverVisible
          },
          on: { hide: _vm.hideSettingsPopover, back: _vm.changeSettingsNav },
          slot: "settings"
        },
        [
          !_vm.settingsPopoverNavSelected
            ? _c("markets-ui-settings-navigation", {
                attrs: {
                  slot: "content",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingsPopoverNav
                },
                on: { change: _vm.changeSettingsNav },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          !!_vm.settingsPopoverNavSelected
            ? _c("markets-ui-list", {
                attrs: {
                  slot: "content",
                  variation: "selection",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingsPopoverNavSelectedList
                },
                on: { change: _vm.changeSettingsNavSelected },
                slot: "content"
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }