(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mwc-markets-core"));
	else if(typeof define === 'function' && define.amd)
		define(["mwc-markets-core"], factory);
	else if(typeof exports === 'object')
		exports["mwcMarketsResearch"] = factory(require("mwc-markets-core"));
	else
		root["mwcMarketsResearch"] = factory(root["mwcMarketsCore"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__) {
return 