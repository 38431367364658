<template>
    <markets-ui-container
        :class="namespace()"
        :autoHeight="watchedSettings.autoHeight"
        :skin="watchedSettings.skin"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        :showLoading="showLoading"
        @setting-click="toggleSettings"
    >
        <span slot="title" :class="namespace('header__bold')">
            {{ title.main }}: <span>{{ title.sub }}</span>
        </span>
        <div slot="content">
            <markets-ui-menus
                v-if="showWebLayout && !errorMessage"
                :dataModel="settingsPopoverNavSelectedList"
                :skin="watchedSettings.skin"
                :class="namespace('view-menus')"
                @change="changeViewType"
            ></markets-ui-menus>
        </div>
        <div slot="content" :class="classes">
            <component
                v-if="!showLoading && !errorMessage"
                :initedLabels="initedLabels"
                :dataModel="subComponentData"
                :settings="settings"
                v-bind:is="currentSubComponent"
                :skin="watchedSettings.skin"
            >
            </component>
        </div>
        <markets-ui-settings
            slot="settings"
            ref="researchUiSettings"
            :skin="watchedSettings.skin"
            :showBack="!!settingsPopoverNavSelected"
            :triggered-by="settingsPopoverTriggeredId"
            :visible="settingsPopoverVisible"
            @hide="hideSettingsPopover"
            @back="changeSettingsNav"
        >
            <markets-ui-settings-navigation
                v-if="!settingsPopoverNavSelected"
                :skin="watchedSettings.skin"
                :dataModel="settingsPopoverNav"
                @change="changeSettingsNav"
                slot="content"
            >
            </markets-ui-settings-navigation>
            <markets-ui-list
                v-if="!!settingsPopoverNavSelected"
                variation="selection"
                :skin="watchedSettings.skin"
                :dataModel="settingsPopoverNavSelectedList"
                @change="changeSettingsNavSelected"
                slot="content"
            >
            </markets-ui-list>
        </markets-ui-settings>
    </markets-ui-container>
</template>

<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
const { mixins, utils } = mwcMarketsCore;
import HIGHLIGHTS from './components/highlights';
import COMPETITORS from './components/competitors';
import BULLSVSBEARS from './components/bulls-vs-bears';
import { getComponentName, getDataPropertyBySubComponent } from './common';

export default {
    name: 'mwc-markets-research-ui',
    mixins: [mixins.componentUI],
    props: {
        dataModel: {
            type: Object,
            default: function() {
                return {
                    highlights: {
                        basicInfo: null,
                        smartTextInfo: null
                    },
                    bullsVsBears: {
                        bullsSay: null,
                        bearsSay: null
                    },
                    competitors: [],
                    security: {}
                };
            }
        },
        showLoading: {
            type: Boolean,
            default: false
        }
    },
    components: {
        HIGHLIGHTS,
        COMPETITORS,
        BULLSVSBEARS
    },
    data() {
        return {
            settingsPopoverVisible: false,
            settingsPopoverTriggeredId: null,
            settingsPopoverNavSelected: undefined
        };
    },
    watch: {
        'dataModel.security': {
            handler(val) {
                if (val) {
                    this.changeSettings(this._getStoreSettings());
                }
            },
            deep: true
        }
    },
    /**
     * Set name space and default settings.
     */
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('research');
    },
    computed: {
        classes() {
            const array = [this.namespace('content')];
            if (!this.watchedSettings.autoHeight) {
                array.push(this.namespace('content--scroll'));
                if (this.showWebLayout) {
                    array.push(this.namespace('content--web-layout'));
                }
            }
            return array;
        },
        title() {
            return {
                main: this.initedLabels['title'],
                sub: this.dataModel.security
            };
        },
        /**
         *  Set popover nav menu.
         * @returns {{name: viewType, id: string, title: viewType }[]}
         */
        settingsPopoverNav() {
            return [
                {
                    id: 'viewType',
                    name: this.initedLabels[this.watchedSettings.viewType],
                    title: this.initedLabels['viewType']
                }
            ];
        },
        /**
         *  Set popover nav list.
         * @returns {{name: viewType, id: viewType value, selected: boolean}[]}
         */
        settingsPopoverNavSelectedList() {
            {
                return ['highlights', 'competitors', 'bullsVsBears'].map(
                    viewType => {
                        return {
                            id: viewType,
                            name: this.initedLabels[viewType],
                            selected: viewType === this.watchedSettings.viewType
                        };
                    }
                );
            }
        },
        currentSubComponent() {
            return getComponentName(this.watchedSettings.viewType);
        },
        /**
         * Current sub component data.
         */
        subComponentData() {
            return this.dataModel[
                getDataPropertyBySubComponent(this.currentSubComponent)
            ];
        }
    },
    methods: {
        /**
         * callback for resize observer
         * @param entry
         */
        _resizeObserverCallback({ entry }) {
            this.watchedSettings.width = entry.contentRect.width;
            this.hideSettingsPopover();
        },
        /**
         * Change popover visible state.
         * This will invoke in horizon.
         * @param target
         */
        toggleSettings(e) {
            this.settingsPopoverVisible = !this.settingsPopoverVisible;
            this.settingsPopoverTriggeredId = utils.computeTriggerId(e.target);
        },
        /**
         * Change view type to display
         */
        changeViewType({ id }) {
            this.watchedSettings.viewType = id;
            this.changeSettings(this._getStoreSettings());
            this.$emit('view-type-changed', id);
        },
        /**
         * Hide popover setting.
         */
        hideSettingsPopover() {
            this.settingsPopoverVisible = false;
            this.settingsPopoverNavSelected = null;
            this.$emit('settings-popover-hide');
        },
        /**
         * Change current selected nva item.
         * @param item - item id.
         */
        changeSettingsNav(item) {
            this.settingsPopoverNavSelected = item;
            this.$refs.researchUiSettings.enableTrapFocus();
        },
        /**
         * Change current nav to set the sub component type.
         * @param item - sub component type.
         */
        changeSettingsNavSelected(item) {
            if (this.settingsPopoverNavSelected) {
                if (this.settingsPopoverNavSelected.id === 'viewType') {
                    this.changeViewType(item);
                }
            }
            this.settingsPopoverNavSelected = null;
            this.hideSettingsPopover();
        },
        /**
         * Store settings data.
         * @returns {{symbol: string, viewType: (string)}}
         * @private
         */
        _getStoreSettings() {
            const symbol = utils.isEmptyObject(this.dataModel.security)
                ? this.watchedSettings.symbol
                : this.getSavedSymbol(this.dataModel.security);
            if (symbol) {
                return {
                    symbol,
                    viewType: this.watchedSettings.viewType
                };
            } else {
                return null;
            }
        }
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-research';
.#{$namespace} {
    &.markets-ui-container__fixed-height {
        .#{$namespace}-content {
            height: 100%;
            &--scroll {
                overflow: auto;
            }
            &--web-layout {
                height: calc(100% - 50px);
            }
        }
    }

    &-view-menus {
        padding: $mds-space-1-and-a-half-x;
        height: 50px;
    }
}
</style>
