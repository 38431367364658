<template>
    <section :class="classes">
        <div :class="namespace('layout-grid__row')">
            <div :class="namespace('layout-grid__column')">
                <div :class="namespace('header')">
                    <img
                        :src="bullsSvg"
                        aria-hidden="true"
                        :alt="initedLabels.bullsSay"
                    />
                    <h2>
                        {{ initedLabels.bullsSay }}
                    </h2>
                </div>
                <article
                    v-for="item of this.dataModel.bullsSay"
                    :key="item"
                    :class="namespace('article--bulls')"
                >
                    <div :class="namespace('article__content')">
                        <p>
                            {{ item }}
                        </p>
                    </div>
                </article>
            </div>
            <div :class="namespace('layout-grid__column')">
                <div :class="namespace('header')">
                    <img
                        :src="bearsSvg"
                        aria-hidden="true"
                        :alt="initedLabels.bearsSay"
                    />
                    <h2>
                        {{ initedLabels.bearsSay }}
                    </h2>
                </div>
                <article
                    v-for="item of this.dataModel.bearsSay"
                    :key="item"
                    :class="namespace('article--bears')"
                >
                    <div :class="namespace('article__content')">
                        <p>
                            {{ item }}
                        </p>
                    </div>
                </article>
            </div>
        </div>
    </section>
</template>

<script>
import mwcMarketsCore from 'mwc-markets-core';
import bullsSvg from '../assets/images/bulls.svg';
import bearsSvg from '../assets/images/bears.svg';
const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'research-bulls-vs-bears',
    mixins: [mixins.componentUI],
    props: {
        skin: {
            type: String,
            default: ''
        },
        dataModel: {
            type: Object,
            default: function() {
                return {
                    bullsSay: [],
                    bearsSay: []
                };
            }
        },
        /**
         * The labels, come from research ui component.
         */
        initedLabels: {
            type: Object
        }
    },
    data() {
        return {
            isMobile: false,
            bullsSvg: null,
            bearsSvg: null
        };
    },
    created() {
        this.namespace = utils.namespace('research-bulls-vs-bears');
        this.bullsSvg = bullsSvg;
        this.bearsSvg = bearsSvg;
    },
    watch: {
        dataModel: {
            handler() {
                this.$nextTick(() => {
                    this._alignHeight();
                });
            },
            deep: true
        }
    },
    computed: {
        classes() {
            const cls = [this.namespace()];
            const skinSuffix = utils.getSkinSuffix(this.skin);
            cls.push(`${this.namespace()}--${skinSuffix}`);
            if (!this.isMobile) {
                cls.push(this.namespace('-flex'));
            }
            return cls;
        },
        skinStyle() {
            const skinSuffix = utils.getSkinSuffix(this.skin);
            return `${this.namespace()}--${skinSuffix}`;
        }
    },
    mounted() {
        utils.resizeObserver.observe(this.$el, this._resizeObserverCallback);
    },
    beforeDestroy() {
        utils.resizeObserver.unobserve(this.$el, this._resizeObserverCallback);
    },
    methods: {
        _resizeObserverCallback({ entry }) {
            this.isMobile = entry.contentRect.width < 600;
            this._alignHeight();
        },
        _alignHeight() {
            const bullsEls = this.$el.querySelectorAll(
                `.${this.namespace('article--bulls')}>div`
            );
            const bearsEls = this.$el.querySelectorAll(
                `.${this.namespace('article--bears')}>div`
            );
            bullsEls.forEach(bullEl => {
                bullEl.style.height = '';
            });
            bearsEls.forEach(bearEl => {
                bearEl.style.height = '';
            });
            if (!this.isMobile) {
                bullsEls.forEach((bullEl, index) => {
                    if (bearsEls[index]) {
                        const bullHeight = bullEl.getBoundingClientRect()
                            .height;
                        const bearHeight = bearsEls[
                            index
                        ].getBoundingClientRect().height;
                        const maxHeight = `${Math.max(
                            bullHeight,
                            bearHeight
                        )}px`;
                        bullEl.style.height = maxHeight;
                        bearsEls[index].style.height = maxHeight;
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';

$namespace: 'mwc-markets-research-bulls-vs-bears';

@mixin article {
    align-items: flex-start;
    margin: $mds-space-half-x;
    border-top-style: solid;
    border-color: $mds-color-neutral-50;
    border-top-width: $mds-space-half-x;
    min-width: 300px;
    width: 96%;
}

.#{$namespace} {
    &--flex {
        .#{$namespace} {
            &-layout-grid__row {
                display: flex;
            }
            &-layout-grid__column {
                width: 50%;
            }
        }
    }
    &-layout-grid__row {
        width: 100%;
    }
    &-title {
        width: 50%;
    }
    &-header {
        margin: $mds-space-half-x;
        display: flex;
        padding: $mds-space-2-x;
        align-items: center;
        h2 {
            margin-left: $mds-space-4-x;
        }
    }
    &-article--bears,
    &-article--bulls {
        @include article;
    }
    &-article__content {
        margin: $mds-space-4-x 0;
        p {
            font-size: 16px;
        }
    }
    &---not-mobile-layout-item {
        display: flex;
        justify-content: space-around;
    }
    &--dark-gray {
        color: $mds-text-color-primary-on-dark;
    }
}
</style>
