<template>
    <RESEARCHUI
        ref="UI"
        :showLoading="showLoading"
        :skin="skin"
        :dataModel="dataModel"
        :errorCode="error.code"
        :settings="settings"
        :labels="mergeLabels(defaultConfig.labels)"
        :formatter="formatter"
        @settings-changed="changeSettings"
        @symbol-changed="changeSymbol"
        @settings-popover-hide="hideSettingsPopover"
        @view-type-changed="changeViewType"
    >
    </RESEARCHUI>
</template>

<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import {
    getResearchDataProperty,
    getSubComponents,
    getComponentName
} from './common';
import RESEARCHUI from './research-ui';

const LAST_PRICE_AND_TIME_PROPERTY_NAME = {
    lastPrice: 'lastPrice',
    tradeTime: 'tradeTime'
};
const PRICE_FAIR_VALUE_PROPERTY = 'priceFairValue';
const COMPONENT_NAME = 'mwc-markets-research';
const DEFAULT_VIEW_TYPE = 'highlights';
const { utils, mixins } = mwcMarketsCore;
export default {
    name: COMPONENT_NAME,
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    components: {
        RESEARCHUI
    },
    props: {
        symbol: {
            dataType: String
        }
    },
    /**
     * The component data.We must set data model struct like this,  because the sub component
     * use this filed as data model.
     * @returns {{defaultConfig: {}, name: string, dataModel: {highlights: {}, bullsVsBears: {}}, showLoading: boolean}}
     */
    data() {
        return {
            name: COMPONENT_NAME,
            defaultConfig: {
                settings: {
                    layout: '',
                    autoHeight: true,
                    symbol: '',
                    showHeader: false,
                    showSetting: true,
                    showBorder: false,
                    updateLastAccessTime: true,
                    languageId: 'en-US',
                    type: '',
                    skin: '',
                    // the default navigation value
                    viewType: DEFAULT_VIEW_TYPE,
                    dataType: '',
                    stickyLeadBreakPoints: [
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 3,
                            minWidth: 0,
                            maxWidth: 640
                        }
                    ]
                },
                labels: labels,
                // the components name
                componentsLabels: getSubComponents(),
                intlNamespace: COMPONENT_NAME
            },
            dataModel: {
                security: {},
                highlights: {},
                bullsVsBears: {},
                competitors: []
            },
            showLoading: false,
            securities: []
        };
    },
    watch: {
        symbol: function(value) {
            if (value) {
                this.changeSymbol(value);
            }
        }
    },
    created() {
        // init
        this.settings.viewType = getComponentName(this.settings.viewType)
            ? this.settings.viewType
            : DEFAULT_VIEW_TYPE;
        this.watchedSymbol = this.settings.symbol;
        this.type = null;
        this.namespace = utils.namespace('research');
        this.initialized = false;
        this.currentDataType = null;
        // promise will get from market data, will first init when change date type(UI component trigger).
        this.pullSubscribePromise = null;
        this.streamSubscribePromise = null;
        this.targetVisibility = false;
        this.changeDataType(this.settings.dataType);
    },
    /**
     * When the component mounted, set observe and get data.
     */
    mounted() {
        utils.visibilityObserver.observe(
            this.$el,
            this._visibilityObserverCallback
        );
        if (!utils.isHidden(this.$el)) {
            if (!this.initialized) {
                this.initialize();
            }
        }
    },
    beforeDestroy() {
        // before destroy must unsubscribe data.
        utils.visibilityObserver.unobserve(
            this.$el,
            this._visibilityObserverCallback
        );
        this._unsubscribe(this.currentDataType, this.securities);
    },
    methods: {
        initialize() {
            this.initialized = true;
            if (this.watchedSymbol) {
                this.changeSymbol(this.watchedSymbol);
            }
        },
        /**
         * This method will invoke when target visibility changed.
         * @param {boolean} visibility - The target visibility value.
         */
        _visibilityObserverCallback({ visibility }) {
            if (visibility) {
                if (!this.initialized) {
                    this.initialize();
                } else {
                    this._unsubscribe(this.currentDataType, this.securities);
                    this._subscribe(this.currentDataType, this.securities);
                }
            } else {
                if (!this.targetVisibility) {
                    this._unsubscribe(this.currentDataType, this.securities);
                }
                this.targetVisibility = false;
            }
        },
        changeTargetElement() {
            this.targetVisibility = true;
        },
        /**
         * Change data type, you can use stream or pull to get data.
         * @param {string} value - The date type.
         */
        changeDataType(value) {
            if (!this.mktdata) {
                return;
            }
            const oldValue = this.currentDataType;
            this._unsubscribe(oldValue, this.securities);
            this.currentDataType = value === 'stream' ? value : 'pull';
            if (!this[`${this.currentDataType}SubscribePromise`]) {
                this[`${this.currentDataType}SubscribePromise`] = this.mktdata[
                    this.currentDataType
                ]();
            }
            this._subscribe(this.currentDataType, this.securities);
        },
        /**
         * Get last price and time, when first init.
         * @param{Object} highlightsData - The other date from research highlights api.
         * @param{string} highlightsPropertyName - The highlights data property name.
         * @private
         */
        _getHighlightsPriceAndTime(highlightsData, highlightsPropertyName) {
            return this.mktdata
                .quotes({
                    securities: [this.watchedSymbol]
                })
                .then(result => {
                    if (
                        result.length === 1 &&
                        this._fillDataModel(
                            highlightsData,
                            highlightsPropertyName
                        )
                    ) {
                        const lastPrice = utils.find(result[0].quotes, item => {
                            return (
                                item.name ===
                                LAST_PRICE_AND_TIME_PROPERTY_NAME.lastPrice
                            );
                        }).value;
                        const tradeTime = utils.find(result[0].quotes, item => {
                            return (
                                item.name ===
                                LAST_PRICE_AND_TIME_PROPERTY_NAME.tradeTime
                            );
                        }).value;
                        this._fillHighlightsPriceAndTime(lastPrice, tradeTime);
                        this.showLoading = false;
                        this.securities.push(this.watchedSymbol);
                        this._subscribe(this.currentDataType, [
                            this.watchedSymbol
                        ]);
                        this.renderCompleted = true;
                    } else {
                        return Promise.reject(
                            'highlights quotes data get failed'
                        );
                    }
                });
        },
        _getCompetitorsPrice(competitorsData, competitorsPropertyName) {
            const secIds = [];
            competitorsData.data.forEach(basicInfo => {
                basicInfo['id'] = basicInfo.name;
                if (basicInfo.secId && basicInfo.fairValue) {
                    secIds.push(basicInfo.secId);
                    this.securities.push(basicInfo.secId);
                }
            });
            if (this._fillDataModel(competitorsData, competitorsPropertyName)) {
                return this.mktdata
                    .quotes({
                        securities: secIds
                    })
                    .then(results => {
                        const lastPrices = {};
                        results.forEach(result => {
                            lastPrices[result.security] = utils.find(
                                result.quotes,
                                item => {
                                    return (
                                        item.name ===
                                        LAST_PRICE_AND_TIME_PROPERTY_NAME.lastPrice
                                    );
                                }
                            ).value;
                        });
                        this._fillCompetitorsPrice(
                            competitorsData.data,
                            lastPrices
                        );
                        this.showLoading = false;
                        this._subscribe(this.currentDataType, secIds);
                        this.renderCompleted = true;
                    });
            }
        },
        /**
         * Fill some last price to competitors.
         * @param{string}competitors - The competitors array.
         * @param{object}lastPrices - The last prices.
         * @private
         */
        _fillCompetitorsPrice(competitors, lastPrices = {}) {
            const competitorsProperty = getResearchDataProperty()
                .competitorsData;
            if (this.dataModel[competitorsProperty]) {
                const data = [];
                competitors.forEach(basicInfo => {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            lastPrices,
                            basicInfo.secId
                        )
                    ) {
                        basicInfo[PRICE_FAIR_VALUE_PROPERTY] =
                            lastPrices[basicInfo.secId] - basicInfo.fairValue;
                        data.push(basicInfo);
                    }
                });
                this.dataModel[competitorsProperty] = data;
            }
        },
        /**
         * Fill a last price to competitors.
         * @param{string}competitors - The competitors array.
         * @param{Number}lastPrice - The last price.
         * @private
         */
        _fillCompetitorsPrices(competitors, lastPrice) {
            const competitorsProperty = getResearchDataProperty()
                .competitorsData;
            if (this.dataModel[competitorsProperty]) {
                competitors.forEach((basicInfo, index) => {
                    if (basicInfo.secId === lastPrice.secId) {
                        basicInfo[PRICE_FAIR_VALUE_PROPERTY] =
                            lastPrice.price - basicInfo.fairValue;
                        this.$set(
                            this.dataModel[competitorsProperty],
                            index,
                            basicInfo
                        );
                    }
                });
            }
        },
        /**
         * Fill price and time to highlights data model.
         * @param{Number}lastPrice - The last price.
         * @param{string}tradeTime - The last price trade time.
         * @private
         */
        _fillHighlightsPriceAndTime(lastPrice, tradeTime) {
            const highlightsProperty = getResearchDataProperty().highlightsData;
            if (this.dataModel[highlightsProperty].basicInfo) {
                this.$set(
                    this.dataModel[highlightsProperty].basicInfo,
                    LAST_PRICE_AND_TIME_PROPERTY_NAME.lastPrice,
                    lastPrice
                );
                this.$set(
                    this.dataModel[highlightsProperty].basicInfo,
                    LAST_PRICE_AND_TIME_PROPERTY_NAME.tradeTime,
                    tradeTime
                );
            }
        },
        /**
         * Get component data by sub component name.
         * @private
         */
        _getResearchData() {
            // must clear error before get data.
            this.error = {};
            // Because id service have cache, so we can frequently invoke this method.
            const { security } = this.dataModel;
            if (security.mstarType === 'ST') {
                const subComponent = getComponentName(this.settings.viewType);
                switch (subComponent) {
                    case getSubComponents().highlightsComponent: {
                        const highlightsProperty = getResearchDataProperty()
                            .highlightsData;
                        if (
                            Object.keys(this.dataModel[highlightsProperty])
                                .length === 0
                        ) {
                            this.showLoading = true;
                            this.mktdata
                                .getResearchHighlights({
                                    security: this.watchedSymbol
                                })
                                .then(result =>
                                    this._getHighlightsPriceAndTime(
                                        result,
                                        highlightsProperty
                                    )
                                )
                                .catch(() => {
                                    this._setError(this.ERROR_TYPES.NODATA);
                                });
                        }
                        break;
                    }
                    case getSubComponents().competitorsComponent: {
                        const competitorsProperty = getResearchDataProperty()
                            .competitorsData;
                        if (
                            Object.keys(this.dataModel[competitorsProperty])
                                .length === 0
                        ) {
                            this.showLoading = true;
                            this.mktdata
                                .getResearchCompetitors({
                                    security: this.watchedSymbol
                                })
                                .then(result =>
                                    this._getCompetitorsPrice(
                                        result,
                                        competitorsProperty
                                    )
                                )
                                .catch(() => {
                                    this._setError(this.ERROR_TYPES.NODATA);
                                });
                        }
                        break;
                    }
                    case getSubComponents().bullsVsBearsComponent: {
                        const bullsVsBearsProperty = getResearchDataProperty()
                            .bullsVsBearsData;
                        if (
                            Object.keys(this.dataModel[bullsVsBearsProperty])
                                .length === 0
                        ) {
                            this.showLoading = true;
                            this.mktdata
                                .getResearchBullsVsBears({
                                    security: this.watchedSymbol
                                })
                                .then(result => {
                                    this.showLoading = false;
                                    this._fillDataModel(
                                        result,
                                        bullsVsBearsProperty
                                    );
                                    this.renderCompleted = true;
                                })
                                .catch(() => {
                                    this._setError(this.ERROR_TYPES.NODATA);
                                });
                        }
                        break;
                    }
                }
            } else {
                this._setError(this.ERROR_TYPES.INVALID_INVESTMENT_TYPE);
            }
        },
        /**
         *  Fill data model.
         * @param resultData - the response data and status.
         * @param property - the  sub component data property.
         * @return Object if success return data.
         * @private
         */
        _fillDataModel(resultData, property) {
            if (!resultData || resultData.status.errorCode !== '0') {
                this._setError(this.ERROR_TYPES.NODATA);
                return null;
            } else if (
                (Array.isArray(this.dataModel[property]) &&
                    this.dataModel[property].length === 0) ||
                Object.keys(this.dataModel[property]).length === 0
            ) {
                this.dataModel[property] = resultData.data;
                return this.dataModel[property];
            } else {
                return null;
            }
        },
        clickHoverMenu({ item, row }) {
            this.exposeSecurity(row.id)
                .then(params => {
                    this.$emit('hover-menu-click', item, params);
                })
                .catch(() => {
                    this.$emit('hover-menu-click', item, row.id);
                });
        },
        /**
         * Change component symbol.
         * This method will invoke in the created method.
         */
        changeSymbol(value) {
            if (!this.mktdata) {
                return;
            }
            this._unsubscribe(this.currentDataType, this.securities);
            this.watchedSymbol = value;
            this.showLoading = true;
            this.mktdata
                .securities([this.watchedSymbol])
                .then(securities => {
                    this.acceptTicker(securities[0].queryKey);
                    // clear data model, because need use data model to judge if get data.
                    this.dataModel = {
                        security: securities[0],
                        highlights: {},
                        bullsVsBears: {},
                        competitors: []
                    };
                    this.securities = [];
                    if (this.initialized) {
                        this._getResearchData();
                    }
                })
                .catch(() => {
                    this._setError(this.ERROR_TYPES.INVALID_INVESTMENT);
                    this.showLoading = false;
                });
        },
        hideSettingsPopover() {
            this.$emit('settings-popover-hide');
        },
        _setError(error) {
            this.error = error;
            this.showLoading = false;
        },
        /**
         * Update data when subscriber get some new date.(mixin)
         * @param{string}security - The security.
         * @param{Array}updates - The updated data.
         */
        updateData(security, updates) {
            if (this.watchedSymbol === security) {
                const lastPrice = utils.find(updates, item => {
                    return (
                        item.name ===
                        LAST_PRICE_AND_TIME_PROPERTY_NAME.lastPrice
                    );
                });
                const tradeTime = utils.find(updates, item => {
                    return (
                        item.name ===
                        LAST_PRICE_AND_TIME_PROPERTY_NAME.tradeTime
                    );
                });
                if (lastPrice && tradeTime) {
                    this._fillHighlightsPriceAndTime(
                        lastPrice.value,
                        tradeTime.value
                    );
                }
            } else {
                const competitorsProperty = getResearchDataProperty()
                    .competitorsData;
                const lastPrice = utils.find(updates, item => {
                    return (
                        item.name ===
                        LAST_PRICE_AND_TIME_PROPERTY_NAME.lastPrice
                    );
                });
                if (lastPrice) {
                    this._fillCompetitorsPrices(
                        this.dataModel[competitorsProperty],
                        {
                            secId: security,
                            price: lastPrice.value
                        }
                    );
                }
            }
        },
        /**
         * Subscribe data by data type.
         * @param{string}dataType - The data type, you can use pull or stream.
         * @param{Array}symbols - The symbol want to subscribe.
         */
        _subscribe(dataType, symbols) {
            if (dataType && symbols.length > 0) {
                this.isSubscribing = true;
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.subscribe(symbols, this.subscribeListener, {
                            updateLastAccessTime: this.settings
                                .updateLastAccessTime
                        });
                    })
                    .catch(() => {
                        this.showLoading = false;
                    });
            }
        },
        _unsubscribe(dataType, symbols) {
            if (!this.isSubscribing) {
                return;
            }
            this.isSubscribing = false;
            if (dataType && symbols.length > 0) {
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.unsubscribe(symbols, this.subscribeListener);
                    })
                    .catch(() => {});
            }
        },
        /**
         * The hook method, when sub component changed, emit
         * this method to get sub component data.
         * @private
         */
        changeViewType(viewType) {
            if (this.settings.viewType !== viewType) {
                this.settings.viewType = viewType;
                this._unsubscribe(this.currentDataType, this.securities);
                this._getResearchData();
            }
        }
    }
};
</script>
