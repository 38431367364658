const SUB_COMPONENT = {
    highlightsComponent: 'HIGHLIGHTS',
    competitorsComponent: 'COMPETITORS',
    bullsVsBearsComponent: 'BULLSVSBEARS'
};
const RESEARCH_DATA_PROPERTY = {
    highlightsData: 'highlights',
    bullsVsBearsData: 'bullsVsBears',
    competitorsData: 'competitors'
};

/**
 * Get component name by view type.
 * @param viewType - The view type.
 * @returns {{competitorComponent: string|bullsVsBearsComponent: string|highlightsComponent: string}}
 */
export function getComponentName(viewType) {
    return SUB_COMPONENT[viewType + 'Component'];
}

/**
 * Get sub all component name.
 * @returns {{competitorComponent: string, bullsVsBearsComponent: string, highlightsComponent: string}}
 */
export function getSubComponents() {
    return SUB_COMPONENT;
}

/**
 * Get research data property.
 * @returns {{competitorsData: string, highlightsData: string, bullsVsBearsData: string}}
 */
export function getResearchDataProperty() {
    return RESEARCH_DATA_PROPERTY;
}

/**
 * Get data model property by component name.
 * @param subComponentName - The sub component name.
 * @returns {string}
 */
export function getDataPropertyBySubComponent(subComponentName) {
    switch (subComponentName) {
        case SUB_COMPONENT.highlightsComponent: {
            return RESEARCH_DATA_PROPERTY.highlightsData;
        }
        case SUB_COMPONENT.bullsVsBearsComponent: {
            return RESEARCH_DATA_PROPERTY.bullsVsBearsData;
        }
        case SUB_COMPONENT.competitorsComponent: {
            return RESEARCH_DATA_PROPERTY.competitorsData;
        }
    }
}
