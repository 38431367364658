var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: _vm.classes }, [
    _c("div", { class: _vm.namespace("layout-grid__row") }, [
      _c(
        "div",
        { class: _vm.namespace("layout-grid__column") },
        [
          _c("div", { class: _vm.namespace("header") }, [
            _c("img", {
              attrs: {
                src: _vm.bullsSvg,
                "aria-hidden": "true",
                alt: _vm.initedLabels.bullsSay
              }
            }),
            _vm._v(" "),
            _c("h2", [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.initedLabels.bullsSay) +
                  "\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _vm._l(this.dataModel.bullsSay, function(item) {
            return _c(
              "article",
              { key: item, class: _vm.namespace("article--bulls") },
              [
                _c("div", { class: _vm.namespace("article__content") }, [
                  _c("p", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(item) +
                        "\n                    "
                    )
                  ])
                ])
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.namespace("layout-grid__column") },
        [
          _c("div", { class: _vm.namespace("header") }, [
            _c("img", {
              attrs: {
                src: _vm.bearsSvg,
                "aria-hidden": "true",
                alt: _vm.initedLabels.bearsSay
              }
            }),
            _vm._v(" "),
            _c("h2", [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.initedLabels.bearsSay) +
                  "\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _vm._l(this.dataModel.bearsSay, function(item) {
            return _c(
              "article",
              { key: item, class: _vm.namespace("article--bears") },
              [
                _c("div", { class: _vm.namespace("article__content") }, [
                  _c("p", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(item) +
                        "\n                    "
                    )
                  ])
                ])
              ]
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }