var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: [_vm.namespace("section"), _vm.skinStyle] }, [
    _c(
      "div",
      { class: [_vm.namespace("layout")] },
      [
        _c("markets-ui-grid", {
          attrs: {
            skin: _vm.skin,
            selectRowIds: _vm.selectRowIds,
            option: _vm.gridOption,
            columns: _vm.gridColumns,
            rows: _vm.dataModel,
            labels: _vm.gridLabels
          },
          on: { "render-complete": _vm.renderComplete }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }